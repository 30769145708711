import React from "react"

import Card from "../components/card"
import Layout from "../components/layout"
import Snippet from "../components/snippet"
import Tickets from "../components/tickets"
import SpeakerCard from "../components/speakerCard"

import layoutStyle from "../components/layout.module.css"
import style from "./partners.module.css"

import partnerJson from "../../content/meta/partners.json"

const IndexPage = () => (
	<Layout
		meta={{
			fullTitle: "Accento Digital — Dev/Java/JavaScript",
			description:
				"Accento Digital is an amazing Java/JavaScript/Ops conference that takes place online on July 7th 2020.",
			keywords: "Java conference Karlsruhe",
			path: "/",
		}}
		wide
	>
		<Snippet id="landing-accento" />
		<Snippet id="landing-motto" />

		<div>
			<Snippet id="landing-tracks-intro" />
			<div className={layoutStyle.cards}>
				{["eberhard-wolff", "angie-jones", "josh-long", "ron-pressler", "nicolas-frankel", "philipp-krenn"].map(speaker => (
					<SpeakerCard
						speakerSlug={speaker}
						size="medium"
						linkImage
						linkName
						showName
						showTalks
						fillVertical
					/>
				))}
			</div>
			<Snippet id="landing-tracks-outro" />
		</div>

		<div>
			<Snippet id="landing-digital" />
			<div className={layoutStyle.cards}>
				{["samantha-humphries", "martijn-verburg", "kevlin-henney", "wolfgang-goerlich", "grace-jansen", "samuel-nitsche"].map(speaker => (
					<SpeakerCard
						speakerSlug={speaker}
						size="medium"
						linkImage
						linkName
						showName
						showInteractives
						fillVertical
					/>
				))}
			</div>
			<Snippet id="landing-digital-outro" />
		</div>

		<Tickets />

		<div>
			<Snippet id="landing-partner" />
			<div className={style.cards}>
				{partnerJson.companies.map(partner => (
					<Card
						size="medium"
						className={style.partner}
						imageSlug={partner.imageSlug}
						imageLink={partner.url}
						invertColors
					/>
				))}
			</div>
		</div>
		<Snippet id="landing-contact" />
	</Layout>
)

export default IndexPage
