import React from "react"

import Snippet from "../components/snippet"
import SnippetCard from "../components/snippetCard"

import style from "./tickets.module.css"
import layoutStyle from "../components/layout.module.css"

import json from "../../content/meta/tickets.json"

const Tickets = () => {
	return (
		<div id="tickets">
			<Snippet id="tickets-intro" />
			<div className={layoutStyle.cards}>
				{json.tickets.map(ticket => (
					<SnippetCard
						key={ticket.snippetId}
						size="medium"
						imageSlug={ticket.imageSlug}
						snippetId={ticket.snippetId}
						fillVertical
						className={style.ticket}
					/>
				))}
			</div>
			<Snippet id="tickets-outro" />
		</div>
	)
}

export default Tickets
